.main-btn {
  background-color: var(--main-color);
  padding: .5rem 3.5rem;
  border: none;
  border-radius: 999px;
  text-align: center;
  color: #fff;
  text-transform: lowercase;
  cursor: pointer;
  font-family: neusa-next-std-condensed, sans-serif;
  display: inline-block;
  font-size: 23px;
  font-weight: 600;

  a {
    text-decoration: none;
    color: #fff;
    font-size: 23px;
    text-transform: lowercase;
    font-weight: 600;
    letter-spacing: 0.05rem;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #A5071DFF;
    color: #fff;

    a {
      color: #fff;
    }
  }


  @media (max-width: 600px) {
    font-size: 18px;

    a {
      font-size: 18px;
    }
  }
}

.sec-btn {
  background-color: var(--secondary-color);
  padding: .5rem 3.5rem;
  border: none;
  border-radius: 999px;
  text-align: center;
  color: var(--main-color);
  text-transform: lowercase;
  cursor: pointer;
  font-family: neusa-next-std-condensed, sans-serif;
  display: inline-block;
  font-size: 23px;
  font-weight: 600;

  a {
    text-decoration: none;
    color: #fff;
    font-size: 23px;
    text-transform: lowercase;
    font-weight: 600;
    letter-spacing: 0.05rem;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #A5071DFF;
    color: #fff;

    a {
      color: #fff;
    }
  }

  @media (max-width: 600px) {
    font-size: 20px;

    a {
      font-size: 20px;
    }
  }
}

/* You can add global styles to this file, and also import other style files */
@import "minireset.css/minireset";
@import "bootstrap/dist/css/bootstrap-grid.min.css";
@import "ngx-smart-modal/styles/ngx-smart-modal.css";
@import "hamburgers/dist/hamburgers.min.css";
@import 'filepond/dist/filepond.min.css';
@import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css';


@import "assets/scss/inputs";
@import "assets/scss/global";
@import "assets/scss/fonts";
@import "assets/scss/buttons";
@import "assets/scss/static-pages";


:root {
  --main-color: #C10925;
  --background-color: #ffffff;
  --secondary-color: #fff;
  --accent-color: #e9d582;
  --blue-color: #006AA6;
  --danger: #DC3545FF;
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}


img {
  image-rendering: -webkit-optimize-contrast;
  //image-rendering: high-quality !important;
}

html, body {
  height: 100%;
}


body {
  font-family: neusa-next-std-condensed, sans-serif;
  letter-spacing: .05rem;
  background-color: var(--background-color);
  color: #fff;
}

//@tailwind base;
//@tailwind components;
//@tailwind utilities;

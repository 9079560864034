$error-color: white;

.input-row {
  margin-bottom: 10px;
  position: relative;

  .input-component {
    //max-width: 330px;
    margin: auto;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border: 1px solid white;
    -webkit-text-fill-color: var(--main-color);
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    //transition: background-color 5000s ease-in-out 0s;
  }

  //select {
  //  -webkit-appearance: none;
  //  border: 2px solid var(--secondary-color);
  //  background-color: var(--background-color);
  //  padding: 10px 60px;
  //  border-radius: 15px;
  //  width: 100%;
  //  outline: none;
  //  text-align: center;
  //  font-size: 16px;
  //  color: white;
  //
  //  @media (max-width: 768px) {
  //    padding: 8px 35px;
  //
  //  }
  //
  //
  //}

  .show-it {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .required {
    color: white;
    font-size: 12px;
  }


  label {

    &.yellow-bg {
      background-color: var(--secondary-color);

    }

    background-color: var(--main-color);
    color: #fff;
    position: relative;
    top: 9px;
    left: 20px;
    padding: 0 5px;
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  input[type='number'] {
    -webkit-appearance: none;
  }

  select {
    text-align: center;
  }

  input, select, textarea {
    -webkit-appearance: none;
    border: 1px solid white;
    background-color: var(--main-color);
    border-radius: 5px;
    //clip-path: polygon(90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%, 10% 0);
    padding: 17px 20px;
    width: 100%;
    outline: none;
    //text-align: center;
    font-size: 16px;
    //border-radius: 15px;
    color: #fff;
    font-family: neusa-next-std-condensed, sans-serif;
    //position: relative;


    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      color: #d1d1d1;
    }

    &.has-border {
      border: 1px solid #000;
    }

    @media (max-width: 768px) {
      padding: 12px 35px;
    }

    &.has-error {
        border-color: var(--danger);
    }
  }

  .errors {
    color: var(--danger);
    font-size: 12px;
    padding: 5px;
  }

  input[type='checkbox'] {
    -webkit-appearance: checkbox;
  }

}

.checkbox-row {
  .checkbox-wrapper, .radio-wrapper {

    input[type='radio'] + label {
      margin-left: 0px;
      margin-right: 10px;
      color: var(--main-color);
      //margin-bottom: 10px;


      &::before {
        content: '';
        width: 20px;
        height: 20px;
        //border: 1px solid grey;
        //border-radius: 3px;
        display: inline-block;
        cursor: pointer;
        position: relative;
        //top: 3px;
        //background-color: #fff;
        margin-right: 5px;
        margin-bottom: -4px;
        background-image: url('/assets/img/shared/radio-new-somat.svg');
        //image-rendering: -webkit-optimize-contrast;
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    input[type='radio']:checked + label {
      &::before {
        background-image: url('/assets/img/shared/radio-new-somat.svg');
        //image-rendering: -webkit-optimize-contrast;
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        //background: #cc171a;
      }
    }

    input[type='checkbox'] {
      display: none;
    }

    input[type='radio'] {
      display: none;
    }

    input[type='checkbox'] + label {
      position: relative;
      font-size: 18px;
      text-align: left;
      font-weight: bold;
      color: #fff;
      user-select: none;

      &::before {
        content: '';
        width: 16px;
        height: 16px;
        border: 1px solid grey;
        border-radius: 3px;
        display: inline-block;
        cursor: pointer;
        position: relative;
        top: 3px;
        background-color: #fff;
        margin-right: 5px;
        margin-bottom: 2px;
      }

      p {
        display: inline;
        text-transform: uppercase;
        font-size: 15px;
      }

      a {
        color: black
      }
    }

    input[type='radio']:checked + label {

    }

    input[type='checkbox']:checked + label {
      &::before {
        background-image: url('/assets/img/shared/tick.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        //background: #cc171a;
      }
    }

    &.has-error {
      input[type='radio'] + label,
      input[type='checkbox'] + label {
        color: $error-color;
      }
    }
  }
}

@font-face {
  font-family: 'Gotham MX';
  src: url('/assets/fonts/subset-GothamMX-CondensedMedium.woff2') format('woff2'),
  url('/assets/fonts/subset-GothamMX-CondensedMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham MX';
  src: url('/assets/fonts/subset-GothamMX-CondensedBold.woff2') format('woff2'),
  url('/assets/fonts/subset-GothamMX-CondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}



@font-face {
  font-family: 'Bruzh';
  src: url('/assets/fonts/subset-Bruzh.woff2') format('woff2'),
  url('/assets/fonts/subset-Bruzh.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'DIN Pro Cond';
  src: url('/assets/fonts/subset-DINPro-CondensedBold.woff2') format('woff2'),
  url('/assets/fonts/subset-DINPro-CondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Cond';
  src: url('/assets/fonts/subset-DINPro-CondensedMedium.woff2') format('woff2'),
  url('/assets/fonts/subset-DINPro-CondensedMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-BoldOblique.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-BoldOblique.woff') format('woff'),
  url('/assets/fonts/subset-Futura-BoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-Book.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-Book.woff') format('woff'),
  url('/assets/fonts/subset-Futura-Book.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-Condensed.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-Condensed.woff') format('woff'),
  url('/assets/fonts/subset-Futura-Condensed.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-ExtraBold.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-ExtraBold.woff') format('woff'),
  url('/assets/fonts/subset-Futura-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-Bold.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-Bold.woff') format('woff'),
  url('/assets/fonts/subset-Futura-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-Heavy.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-Heavy.woff') format('woff'),
  url('/assets/fonts/subset-Futura-Heavy.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura.woff') format('woff'),
  url('/assets/fonts/subset-Futura.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-HeavyOblique.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-HeavyOblique.woff') format('woff'),
  url('/assets/fonts/subset-Futura-HeavyOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-CondensedBoldOblique.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-CondensedBoldOblique.woff') format('woff'),
  url('/assets/fonts/subset-Futura-CondensedBoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-CondensedLightOblique.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-CondensedLightOblique.woff') format('woff'),
  url('/assets/fonts/subset-Futura-CondensedLightOblique.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-CondensedBold.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-CondensedBold.woff') format('woff'),
  url('/assets/fonts/subset-Futura-CondensedBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-Oblique.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-Oblique.woff') format('woff'),
  url('/assets/fonts/subset-Futura-Oblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura_1.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura_1.woff') format('woff'),
  url('/assets/fonts/subset-Futura_1.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-CondensedExtraBold.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-CondensedExtraBold.woff') format('woff'),
  url('/assets/fonts/subset-Futura-CondensedExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-LightOblique.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-LightOblique.woff') format('woff'),
  url('/assets/fonts/subset-Futura-LightOblique.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-CondExtraBoldObl.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-CondExtraBoldObl.woff') format('woff'),
  url('/assets/fonts/subset-Futura-CondExtraBoldObl.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-CondensedOblique.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-CondensedOblique.woff') format('woff'),
  url('/assets/fonts/subset-Futura-CondensedOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-ExtraBoldOblique.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-ExtraBoldOblique.woff') format('woff'),
  url('/assets/fonts/subset-Futura-ExtraBoldOblique.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-CondensedLight.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-CondensedLight.woff') format('woff'),
  url('/assets/fonts/subset-Futura-CondensedLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-Light.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-Light.woff') format('woff'),
  url('/assets/fonts/subset-Futura-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/subset-Futura-BookOblique.woff2') format('woff2'),
  url('/assets/fonts/subset-Futura-BookOblique.woff') format('woff'),
  url('/assets/fonts/subset-Futura-BookOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Trade Gothic LT Extended';
  src: url('/assets/fonts/subset-TradeGothicLT-BoldExtended.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLT-BoldExtended.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLT-BoldExtended.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std Extended';
  src: url('/assets/fonts/subset-TradeGothicLTStd-Extended.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-Extended.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-Extended.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-Light.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-Light.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TradeGothic LT';
  src: url('/assets/fonts/subset-TradeGothicLT.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLT.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std Cn';
  src: url('/assets/fonts/subset-TradeGothicLTStd-Cn18.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-Cn18.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-Cn18.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std Cn';
  src: url('/assets/fonts/subset-TradeGothicLTStd-BdCn20.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-BdCn20.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-BdCn20.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-Obl.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-Obl.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-Obl.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-Cn18_1.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-Cn18_1.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-Cn18_1.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-Bold.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-Bold.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-BoldExt.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-BoldExt.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-BoldExt.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TradeGothic xMx';
  src: url('/assets/fonts/subset-TradeGothicxMx.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicxMx.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicxMx.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-LightObl.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-LightObl.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-LightObl.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-BdCn20Obl.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-BdCn20Obl.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-BdCn20Obl.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'TradeGothic xMx';
  src: url('/assets/fonts/subset-TradeGothicxMx-BoldTwo.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicxMx-BoldTwo.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicxMx-BoldTwo.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-BdCn20_1.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-BdCn20_1.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-BdCn20_1.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-Cn18Obl.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-Cn18Obl.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-Cn18Obl.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-Light_1.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-Light_1.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-Light_1.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-Bd2.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-Bd2.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-Bd2.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-Extended_1.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-Extended_1.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-Extended_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-BoldObl.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-BoldObl.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-BoldObl.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd-Bd2Obl.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd-Bd2Obl.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd-Bd2Obl.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('/assets/fonts/subset-TradeGothicLTStd.woff2') format('woff2'),
  url('/assets/fonts/subset-TradeGothicLTStd.woff') format('woff'),
  url('/assets/fonts/subset-TradeGothicLTStd.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

